<template>
  <v-container
    id="login"
    class="fill-height"
    tag="section"
    :style="$vuetify.breakpoint.mdAndUp ? 'width: 25%' : 'width: 75%'"
  >
    <v-row justify="center">
      <v-slide-y-transition appear>
        <div style="width: 30em; text-align: center">
          <img
            src="../../assets/logo.png"
            width="150"
            v-if="$vuetify.breakpoint.lgAndDown"
          />
          <img
            src="../../assets/logo.png"
            width="250"
            v-if="!$vuetify.breakpoint.lgAndDown"
          />
          <form
            action
            data-vv-scope="login-form"
            @submit.prevent="validateForm('login-form')"
          >
            <v-card-text>
              <v-row justify="center">
                <v-col class="py-0" cols="12">
                  <v-text-field
                    v-model="email"
                    data-vv-validate-on="blur"
                    v-validate="'required|email'"
                    type="email"
                    data-vv-name="email"
                    color="primary"
                    :label="$t('email')"
                    outlined
                    class="color v-text-field rounded-lg"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  />
                </v-col>
                <v-col cols="12">
                  <v-btn
                    block
                    large
                    color="primary"
                    type="submit"
                    elevation="0"
                    style="font-size: 18px; text-transform: none"
                    >{{ $t("lets_go") }}</v-btn
                  >
                </v-col>
                <a href="/?#/auth/login">{{ $t("back_log_in") }}</a>
              </v-row>
            </v-card-text>
          </form>
        </div>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import MaterialAlert from "../../components/base/MaterialAlert";
export default {
  name: "PagesForgotPassword",

  $_veeValidate: {
    validator: "new",
  },

  components: {
    MaterialAlert,
    PagesBtn: () => import("./components/Btn"),
  },

  data: () => ({
    email: "",
  }),

  mounted() {},

  methods: {
    ...mapActions("auth", ["forgotPassword"]),
    async validateForm(scope) {
      const result = await this.$validator.validateAll(scope);
      if (result) {
        this.$store.commit("auth/RESET_INVALID_CREDENTIALS");
        this.forgotPassword({
          email: this.email,
        }).then((response) => {
          if (response.success) {
            this.$alert(this.$t("mail_send"));
            this.email = null;
          } else this.$alert(this.$t("no_user_mail"));
        });
      }
    },
  },
};
</script>

<style lang="sass">
#login
  .color .v-label
    color: var(--v-primary-base) !important
  input
    padding-top: 0
    padding-bottom: 0
    font-size: 16px !important
    margin-top: 6px
    height: 20px !important

  fieldset
    height: 100%

.v-text-field
  border-radius: 15px
  display: flex
  justify-content: center
  align-items: center

@media (max-width: 400px)

  #login
    .v-input__slot
      padding-bottom: 10px !important
    .v-text-field input
      width: 200px
      height: 15px
  .v-btn:not(.v-btn--round).v-size--large
    height: 40px
  .v-text-field
    border-radius: 15px
    display: flex
    justify-content: center
    align-items: center
  fieldset
    widht: 100% !important
    height: 100%
</style>